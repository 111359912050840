<template>
  <div style="padding: 10px">
    <!--begin::Row-->
    <div class="row mb-7 mt-2">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-muted">ชื่อ</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span class="fw-bolder fs-6 text-dark"
          ><span v-if="Profire.fn_other">{{ Profire.fn_other }}</span>
          <span v-else>{{ Profire.fn }}</span> {{ Profire.firstname }}
          {{ Profire.lastname }}</span
        >
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-muted">เพศ</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span class="fw-bolder fs-6 text-dark">{{ Profire.gender }}</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->
    <!--begin::Row-->
    <div class="row mb-7">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-muted">เบอร์โทรศัพท์</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span class="fw-bolder fs-6 text-dark">{{ Profire.tel }}</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->
    <br />
    <!--begin::Row-->
    <div class="row mb-7">
      <!--begin::Label-->
      <label class="col-12 fw-bold text-muted">ที่อยู่อาศัย</label>
      <!--end::Label-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-muted">บ้านเลขที่</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span class="fw-bolder fs-6 text-dark">{{ Profire.numberhome }}</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-muted">หมู่</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span v-if="Profire.moo" class="fw-bolder fs-6 text-dark">{{
          Profire.moo
        }}</span>
        <span v-else class="fw-bolder fs-6 text-dark">-</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-muted">ซอย</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span v-if="Profire.alleyway" class="fw-bolder fs-6 text-dark">{{
          Profire.alleyway
        }}</span>
        <span v-else class="fw-bolder fs-6 text-dark">-</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-muted">ถนน</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span v-if="Profire.road" class="fw-bolder fs-6 text-dark">{{
          Profire.road
        }}</span>
        <span v-else class="fw-bolder fs-6 text-dark">-</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-muted">ตำบล</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span class="fw-bolder fs-6 text-dark">{{ Profire.district }}</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-muted">อำเภอ</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span class="fw-bolder fs-6 text-dark">{{ Profire.amphoe }}</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-muted">จังหวัด</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span class="fw-bolder fs-6 text-dark">{{ Profire.province }}</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-7">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-muted">โรงเรียน</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span class="fw-bolder fs-6 text-dark">{{ Profire.school }}</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

  </div>
</template>

<script>
import { defineComponent, inject } from "vue";

export default defineComponent({
  name: "Profire",
  setup() {
    const store = inject("store");
    const Profire = store.getters.getProfire;
    return { Profire };
  },
});
</script>
